import axios from "axios";

export default {
    async getTimesheet(id: string) {
        const data = new FormData();
        data.append("limit", "100");
        data.append("orderby", "timesheet_creation_date");
        data.append("orderdir", "desc");
        data.append("where[timesheet_member]", `${id.toString()}`);
        data.append("depth", "2");

        const response = await axios.post("rest/v1/search/timesheet", data, {
            headers: {
                Authorization: process.env.VUE_APP_TOKEN,
            },
        });
        //console.log(response.data);
        return response.data;
    },

    /**
     *
     * Create new timesheet
     */
    async saveTimesheet(timesheet) {
        const response = await axios.post(`rest/v1/create/timesheet`, timesheet, {
            headers: {
                Authorization: `${process.env.VUE_APP_TOKEN}`,
            },
        });
        console.log(response);
        return response;
    },
};
